import { Ref, ref, watch } from 'vue'
import { Autosuggestion, AutosuggestionItem } from '@/Pages/the-home/interfaces/Search'
import { useDebounceFn } from '@vueuse/core'
import axios from 'axios'
import { useLoader } from '@/Composables/loader'
import { Location } from '@/Pages/the-home/interfaces/Search'
type UseSearch = {
    search: Ref<string>
    isOpened: Ref<boolean>
    query: Ref<string>
    isSearchInputValid: Ref<boolean>
    handleSearchInputClick: () => Promise<void>
    selectPlace: (place: AutosuggestionItem) => void
    setInitialData: (location: Location | undefined) => void
    autosuggestionResults: Ref<Autosuggestion[]>
    autosuggestionResultsVisible: Ref<boolean>
    url: Ref<string>
    isLoadingSuggestions: Ref<boolean>
    getSuggestions: (search?: string) => Promise<void>
    getFirstSearchQuery: () => void
    selectAutosuggestionResultViaTheKeyboard: (
        event: KeyboardEvent,
        logicWhenPressingEnter?: () => void
    ) => void
    selectSuggestion: (flatIndex: number) => void,
    activeSuggestionIndex: Ref<number>
}

export function useLocationSearch (): UseSearch {
    const isOpened: Ref<boolean> = ref(false)
    const search: Ref<string> = ref('')
    const query: Ref<string> = ref('')
    const url: Ref<string> = ref('')
    const autosuggestionResults: Ref<Autosuggestion[]> = ref([])
    const autosuggestionResultsVisible = ref(false)
    const isSearchInputValid = ref(true)
    const isPlaceSelected = ref(true)
    const activeSuggestionIndex = ref(-1)

    const { load, isLoading: isLoadingSuggestions } = useLoader()

    watch(search, async (newSearch: string, oldSearch: string) => {
        autosuggestionResultsVisible.value = true
        activeSuggestionIndex.value = -1

        if (!isSearchInputValid.value) {
            isSearchInputValid.value = true
        }

        if (newSearch.toLowerCase() === oldSearch.toLowerCase()) {
            return
        }

        if (isPlaceSelected.value && !isOpened.value) {
            isPlaceSelected.value = false
            return
        }

        await debouncedSuggestionsRequest(newSearch)

        if (!autosuggestionResults.value.length) {
            autosuggestionResultsVisible.value = false
        }
    })

    function setInitialData (location: Location | undefined): void {
        search.value = location?.search || ''
        query.value = location?.search || ''
        url.value = location?.url || ''
    }

    async function handleSearchInputClick (): Promise<void> {
        if (!isOpened.value) {
            await getSuggestions()
            isOpened.value = true
            autosuggestionResultsVisible.value = true
            return
        }

        if (!autosuggestionResults.value.length) {
            return
        }

        autosuggestionResultsVisible.value = true
    }

    function selectPlace (place: AutosuggestionItem): void {
        autosuggestionResultsVisible.value = false
        isPlaceSelected.value = true

        search.value = place.query
        query.value = place.query
        url.value = place.url
    }

    const debouncedSuggestionsRequest = useDebounceFn((search: string) => getSuggestions(search), 300)

    async function getSuggestions (search: string = ''): Promise<void> {
        return load(async () => {
            const { data } = await axios.get('/search-autocomplete', {
                params: {
                    s: search
                }
            })

            autosuggestionResults.value = data.suggestions
        })
    }

    const getFirstSearchQuery = (): void => {
        if (autosuggestionResults.value.length > 0 && autosuggestionResults.value[0].items.length > 0) {
            selectPlace(autosuggestionResults.value[0].items[0])
        }
    }

    // Logic to select a autocomplete suggestion via the keyboard
    const selectAutosuggestionResultViaTheKeyboard = (event: KeyboardEvent, logicWhenPressingEnter: () => void = () => {}): void => {
        if (!autosuggestionResults.value || autosuggestionResults.value.length === 0) return

        const totalSuggestions = autosuggestionResults.value.reduce((total, group) => total + group.items.length, 0)

        if (totalSuggestions === 0) return
        
        switch (event.key) {
            case 'ArrowDown': {
            //  event.preventDefault() is needed right here so that when you click up,
            // the focus on the input does not work and the animation logic does not take the input into account
                event.preventDefault()

                if (activeSuggestionIndex.value < totalSuggestions - 1) {
                    activeSuggestionIndex.value++
                } else {
                    activeSuggestionIndex.value = 0
                }
                break
            }
            case 'ArrowUp': {
            //  event.preventDefault() is needed right here so that when you click down,
            // the focus on the input does not work and the animation logic does not take the input into account
                event.preventDefault()

                if (activeSuggestionIndex.value > 0) {
                    activeSuggestionIndex.value--
                } else {
                    activeSuggestionIndex.value = totalSuggestions - 1
                }
                break
            }
            case 'Enter': {
                if (activeSuggestionIndex.value === -1) {
                    logicWhenPressingEnter()
                    autosuggestionResultsVisible.value = false
                    getFirstSearchQuery()
                    return
                }

                if (activeSuggestionIndex.value >= 0) {
                    selectSuggestion(activeSuggestionIndex.value, logicWhenPressingEnter)
                }
                break
            }
        }
    }

    const selectSuggestion = (flatIndex: number, logicUponSuccessfulSearch: () => void = () => {} ): void => {
        let currentIndex = 0

        for (const group of autosuggestionResults.value) {
            if (flatIndex < currentIndex + group.items.length) {
                const itemIndex = flatIndex - currentIndex
                const suggestion = group.items[itemIndex]

                if (suggestion) {
                    selectPlace(suggestion)
                    logicUponSuccessfulSearch()
                    activeSuggestionIndex.value = -1
                }

                return
            }

            currentIndex += group.items.length
        }
    }

    return {
        isOpened,
        search,
        query,
        url,
        isLoadingSuggestions,
        autosuggestionResults,
        autosuggestionResultsVisible,
        isSearchInputValid,
        handleSearchInputClick,
        selectPlace,
        setInitialData,
        getSuggestions,
        getFirstSearchQuery,
        selectAutosuggestionResultViaTheKeyboard,
        selectSuggestion,
        activeSuggestionIndex
    }
}